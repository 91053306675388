.explore-other-products {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.explore-other-products .gallery-01 .image {
  /* max-width: 380px; */
  width: 400px;
  height: 300px;
  margin: 0 16px 3px 16px !important;
}
.explore-other-products .gallery-01 .suffix {
  display: flex;
  align-items: center;
  padding-left: 12px;
}
.explore-other-products .gallery-01 .suffix h1,
.explore-other-products .gallery-01 .suffix h3,
.explore-other-products .gallery-01 .suffix h4 {
  font-size: 14px;
  margin-left: 3px;
  color: black;
}
@media (orientation: portrait) {
  .gallery {
    overflow-x: auto;
    max-width: 100%;
  }
  .explore-other-products  .gallery-01 {
    justify-content: flex-start;
    
  }
  .explore-other-products .gallery-01 .images {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .explore-other-products .gallery-01 .image {
    max-width: 380px;
    width: 80vw;
  }
}