.toast {
  position: fixed;
  top: -500px;
  right: 0;
  z-index: 5000;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  max-width: 430px;
  opacity: 0;
  
  border: 2px solid #d8d8d8;
  border-color: #a9a9a9;
  box-shadow: 0 0 10px #8a8a8a;
  border-radius: 4px;
  background-color: rgba(255,255,255,0.9);

  cursor: default;
}
.toast .message {
  color: black;
  padding: 26px;
  flex-grow: 1;
  font-weight: 600;
}
.toast .close {
  /* border-left: 2px solid #D3FC51; */
  padding: 26px;
  font-size: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

@media (orientation: portrait) {
  .toast {
    width: 100%;
    max-width: none;
  }
}