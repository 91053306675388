.table-sizes-link {
  font-size: 10px;
  margin: 12px 0 0 0;
}

.product-size {
  padding: 12px;
  border: 3px solid rgba(128, 128, 128, 0.1);
  border-radius: 8px;
  margin-right: 8px;
  cursor: pointer;
  font-size: 16px;
}
.product-size-selected {
  border: 3px solid #601010;
}
.product-size-disabled {
  color: rgba(0,0,0,0.1) !important;
}