.cart-side {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  z-index: 101;
}
.cart-side .cover {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: rgba(255, 255, 255, 0.6);
}
.cart-side .window {
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  background-color: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.cart-side .window .content {
  flex-grow: 1;
  padding: 16px;
  overflow-y: auto;
}
.cart-side .window table {
  width: 100%;
}
.cart-side .window td {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  vertical-align: middle;
  text-align: left;
  padding: 6px;
}
.cart-item-size {
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 30px;
}
.cart-side .empty-cart {
  display: flex;
  justify-content: center;
  color: rgba(0, 0, 0, 0.6);
}
.cart-item-size input[type=number] {
  width: 100%;
  height: 100%;
  text-align: center;
}


@media (orientation: portrait) {
  .cart-side .window {
    width: 80%;
  }
}