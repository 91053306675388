.free-shipping {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #251e1e;
  padding: 16px 0;
  color: #f7e1aa;
  font-weight: 300;
  font-size: 12px;
  box-shadow: 0 6px 35px #6161615a;
  z-index: 2;
  width: 100%;
  text-transform: uppercase;
} 
