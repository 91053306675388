.checkout {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  /* padding: 32px 0; */
}
.checkout .summary {
  width: 100%;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}
.summary .content {
  max-width: 680px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.checkout .content .details {
  position: relative;
  overflow-y: auto;
  max-height: 0;
  transition: max-height 1s;
}
.checkout .content .details-shown {
  max-height: 300px;
}
.checkout .summary img {
  margin-right: 12px;
}
.checkout .summary .top-info {
  max-width: 680px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  cursor: pointer;
}
.checkout .summary-table {
  width: 100%;
  padding: 0 0 16px 0;
  font-size: 14px;
}
.checkout .summary-table .td-content{
  width: 100%;
  text-align: right;
}