.empty-page {
  height: 90vh;
  max-height: 90vh;
}
.sold-out {
  padding: 18px;
  background-color: yellow;
  font-weight: 800;
  justify-content: center;
  display: flex;
  border-radius: 8px;
}

.sizes-sel {
  display: flex;
}
.sizes-sel a {
  text-decoration: none;
  background-color: white;
  color: #601010;
}
.more-info {
  position: relative;
  font-size: 14px;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  width: 100%;
}
.more-info .content {
  display: flex;
  margin: 12px 0; 
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100vh;
  width: 100%;
  justify-content: center;
}
.more-info .left {
  min-width: 550px;
  max-width: 550px;
  padding: 12px;
}
.more-info .right {
  max-width: 520px;
  position: sticky;
  top: 0;
}
.more-info .right .inner {
  padding: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.more-info p {
  margin: 0;
  padding: 0px 8px;
}
.more-info .portrait {
  
}

.each-slide-effect .content {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  /* max-width: 730px; */
  height: 700px;
  background-size: cover;
  background-position: 0 100%;
}

@media (orientation: portrait) {
  .more-info .portrait {
    width: 100vw;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .more-info .content {
    width: 100%;
    margin: 16px 0;
    flex-direction: column;
    max-height: none;
  }
  .more-info .left {
    min-width: auto;
    max-width: none;
    overflow-y: auto;
    max-height: none;
  }
  .more-info .right {
    max-width: none;
  }
}