.button-primary,
.button-secondary {
  background: #361212;
  color: #f7e1aa;
  font-weight: 800;
  font-size: 16px;
  padding: 12px;
  margin-top: 12px;
  border-radius: 4px; 
  border: 1px solid transparent;
  min-Width: 250px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}
.button-secondary {
  background: white;
  color: #000;
  border: 1px solid rgba(0,0,0, 0.18);
}

/* button:hover {
  background-color: rgb(90 3 79);
  color: #ffffff;
} */

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}