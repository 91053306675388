.table-sizes {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.7);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-sizes-window {
  background-color: rgba(255,255,255,1);
  border: 1px solid rgba(0,0,0,0.3);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.table-sizes-window table td {
  border: 1px solid rgba(0,0,0,0.1);
  padding: 8px;
  vertical-align: middle;
  text-align: center;
  font-size: 10px;
}
.table-sizes .description {    
  max-width: 500px;
  font-size: 10px;
  padding: 8px;
}