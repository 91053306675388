.header {
  font-family: "Montserrat", sans-serif;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  flex-direction: column;
  width: 100%;
  min-height: 80px;
  align-items: center;
  z-index: 100;
  transition: background-color 1s;
  padding: 8px 0;
}
.header-white-bg {
  background-color: white;
  border-bottom: rgba(128, 128, 128, 0.18);
}
.header a {
  text-decoration: none;
}
.header .left {
  position: absolute;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 16px;
  font-size: 1.3em;
  padding: 0 8px;
}
.header .right {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 16px;
}
.header .horizontal-menu {
  display: flex;
  font-size: 0.6em;
  padding: 12px 0 0 0;
}
.header .horizontal-menu div a:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.header .horizontal-menu a {
  text-decoration: none;
  text-transform: uppercase;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 8px;
  transition: background-color 0.5s ease-out;
}
.header .horizontal-menu a:hover {
  background-color: #fff;
}