.gallery-02 {
  position: relative;
  width: 100%;
  height: 80vh;
  max-height: 800px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.gallery-02 .images {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.gallery-02 .image {
  position: absolute;
  background-size: cover;
  background-position: bottom center;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  opacity: 0.4;
  transform: scale(1.15);
  visibility: hidden;
}
.gallery-02 .image-sel {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}
.gallery-02 .dots {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  display: flex;
  font-size: 0.5em;
  color: rgba(0,0,0,0.8);
}
.gallery-02 .dot {
  padding: 4px 4px;
  cursor: pointer;
}

@media (orientation: portrait) {
  .gallery-02.image {
    width: 100vw !important;
    margin: 0 0 16px 0 !important;
  }
  .gallery-02 .dots {
    font-size: 1em;
  }
}