.products-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.products-list .gallery-01 .image {
  /* max-width: 380px; */
  width: 500px;
  height: 500px;
  /* margin: 32px !important; */
}
.products-list .gallery-01 .suffix {
  display: flex;
  align-items: center;
  padding-left: 12px;
}
.products-list .gallery-01 .suffix h1,
.products-list .gallery-01 .suffix h3,
.products-list .gallery-01 .suffix h4 {
  font-size: 14px;
  margin-left: 3px;
  color: black;
}
@media (orientation: portrait) {
  
  .products-list .gallery-01 .image {
    /* max-width: 380px; */
    width: 100vw;
    height: 400px;
  }
}