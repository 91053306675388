.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  background: #251e1e;
  color: #fff;
  padding: 50px 0;
  font-size: 12px;
  justify-content: flex-start;
  min-height: 400px;
}
.footer a {
  margin-top: 12px;
  font-size: 12 px;
  color: #fff;
  text-decoration: none;
}