.burger-menu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  z-index: 101;
}
.burger-menu .cover {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: rgba(255, 255, 255, 0.6);
}
.burger-menu .window {
  position: absolute;
  top: 0;
  left: -500px;
  width: 30%;
  height: 100%;
  background-color: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.burger-menu .window .content {
  flex-grow: 1;
  padding: 16px;
  overflow-y: auto;
}
.burger-menu .window .content a {
  text-decoration: none;
  text-transform: uppercase;
}
.burger-menu .window table {
  width: 100%;
}
.burger-menu .window td {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  vertical-align: middle;
  text-align: left;
  padding: 6px;
}
.social-media {
  display: flex;
  padding: 32px;
}
.social-media .item {
  border: 1px solid rgba(0,0,0,0.25);
  border-radius: 4px;
  display: flex; 
  justify-content: center; 
  align-items: center;
}
.social-media .item a {
  padding: 12px; 
}
.burger-menu .close {
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 16px;
}

@media (orientation: portrait) {
  .burger-menu .window {
    width: 80%;
  }
}