.gallery-01 {
  position: relative;
  display: flex;
  justify-content: center;
}
.gallery-01 .images {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.gallery-01 .image {
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 500px;
  height: 750px;
  margin: 0 16px 16px 16px;
  overflow: hidden;
  transition: transform 0.2s ease-out;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
}
.gallery-01 .scale-on-hover:hover {
  transform: scale(1.02);
}
.invisible-button a {
  text-decoration: none;
}

@media (orientation: portrait) {
  .gallery-01 .image {
    width: 100vw;
    margin: 0 0 16px 0 !important;
  }
}